import {useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import {FindParcels} from './FindParcels';
import {PropertyState} from '@houseque/types';

export const useFindParcel = (property: PropertyState) => {
  const [findParcel, queryState] = useLazyQuery(FindParcels, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });
  useEffect(() => {
    if (!property?.address || !property?.location?.city) return;
    const {location} = property;
    findParcel({
      variables: {
        address: `${location.streetNumber} ${location.route}`,
        city: location?.city,
        state: location?.state,
        postalcode: location?.postalCode,
        county: location?.county,
        lat: location?.lat + '',
        long: location?.long + '',
      },
    });
  }, [property?.address, property?.location?.lat]);

  return queryState;
};
