import {gql} from '@apollo/client';

export const GetPropertyInfo = gql`
  query GetParcelDetails($parcelId: String) {
    parcelDetails(parcelId: $parcelId) {
      id
      parcelID
      building {
        yearBuilt
        noOfUnits
        bedrooms
        baths
        quarterBaths
        threeQuarterBaths
        halfBaths
        fullBaths
        totalStories
      }
      areas {
        type
        areaSquareFeet
      }
      taxAmount
      taxYear
      zestimate {
        zestimate
        rental {
          zestimate
        }
      }
    }
  }
`;
