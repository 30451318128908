import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {updateFinancing} from '../slice';
import {IndividualPurchaseState} from '@houseque/types';

export const useUpdateFinancing = () => {
  const dispatch = useDispatch();
  const onUpdateFinancing = useCallback(
    (financingDetails: Partial<IndividualPurchaseState>) => {
      dispatch(updateFinancing(financingDetails));
    },
    [dispatch],
  );
  return onUpdateFinancing;
};
